import React, { useReducer, createContext } from 'react'

import { useDelay, usePage, useTitle } from './hooks'
import { initialState } from './state'
import { reducer, R_GLOBAL } from './reducer'

export const GlobalContext = createContext(null)
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
export { useDelay, usePage, useTitle, R_GLOBAL }
