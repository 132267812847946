// import React from 'react'
import startup from 'startup'
import Router from 'site/enr/Router'
// import SiteLogo from 'site/enr/SiteLogo'
import Root from 'site/enr/Root'

startup(
  {
    name: 'En Requiem',
    name_a: 'an En Requiem',
    name_legal: 'Protos, LLC'
  },
  Router,
  { Root }
)
