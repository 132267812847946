// import './wdyr' // @DEV-REMOVE
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from 'utils/serviceWorker'
import config from 'constants/config'
import { GlobalProvider } from 'reducer/global'
import { NotifyProvider } from 'tools/Notify/resolver'
import AuthXProvider from 'components/Signon/Provider'

export default function startup(conf, Router, props) {
  console.log(`BUILD=${config.build} APP=${config.url.app}`, conf)
  Object.assign(config, conf)
  ReactDOM.render(
    <GlobalProvider>
      <NotifyProvider>
        <AuthXProvider>
          <Router {...props} />
        </AuthXProvider>
      </NotifyProvider>
    </GlobalProvider>,
    document.getElementById('root')
  )
  serviceWorker.unregister()
}

// only include high level providers and configuration things here
// <ErrorBoundary>
