import React from 'react'

export const REQUIREMENTS = (
  <>
    Password should be at least 8 characters long and have a mix of lower,
    uppercase, and special characters (non alphanumeric).
  </>
)

export function Error({ children = undefined }) {
  return (
    <div>
      {REQUIREMENTS}
      {children ? <div className="red pt2 b">{children}</div> : null}
    </div>
  )
}

export function badPassword(word, compare = undefined) {
  if (compare !== undefined && word !== compare) {
    return <Error>Passwords do not match</Error>
  }
  if (word.length < 8) {
    return <Error>Password is not long enough</Error>
  }
  if (word.toLowerCase() === word) {
    return <Error>Password needs upper and lower case characters</Error>
  }
  if (word.replace(/[^a-z0-9]/i, '') === word) {
    return <Error>Password needs special characters</Error>
  }
  if (word.replace(/[0-9]/, '') === word) {
    return <Error>Password needs numbers</Error>
  }
  if (word.replace(/[a-z]/i, '') === word) {
    return <Error>Password needs letters</Error>
  }
  return undefined
}
