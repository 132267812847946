/*
 * @doctests
 *
 * ```js
 * t.is(curry(x => x + 1, 3)(), 4)
 * t.is(curry((x, y) => x + y, 10, 23)(), 33)
 * t.is(curry((x, y) => x + y, 10)(23), 33)
 * ```
 */
export const curry = (fxn, ...args) => fxn.bind(null, ...args)
export const maybe = (value, fallback = null) =>
  value != null ? value : fallback // intentional != covers undefined and null

export const pipe = (...fns) => (x) => fns.reduce((v, f) => f(v), x)

/*
 * @doctests
 *
 * ```js
 * t.is(noop(), undefined)
 * t.is(noop(45), undefined)
 * ```
 */
export const noop = () => {}

/*
 * @doctests
 *
 * ```js
 * t.is(when(true, x => x + 1, x => x - 1)(5), 6)
 * t.is(when(false, x => x + 1, x => x - 1)(5), 4)
 * t.is(when(false, x => x + 1)(5), undefined)
 * ```
 */
export const when = (isTruthy, f7, f6 = noop) => (...args) =>
  (isTruthy ? f7 : f6)(...args)
