import React, { useContext, useCallback } from 'react'
import {
  useHistory,
  NavLink as InnerNavLink,
  Link as InnerLink
} from 'react-router-dom'
// TODO: Move this into global navlink
// import style from 'site/codex/Root/NavBar/index.module.scss'
import { MenuItem } from 'react-menu-list'
import GlobalContext, { R_GLOBAL } from 'reducer/global'
import log from 'utils/log'

function linkTo({ props, state, dispatch, history }) {
  let { logOrigin, onClick: onClickInner, to, push = true, ...args } = props

  let logClick = undefined

  if (logOrigin) {
    logClick = (ev) => {
      log.event({
        category: 'NavLink',
        action: 'click',
        label: logOrigin
      })
    }
  }
  let onClick = (ev) => {
    logClick && logClick()
    if (to && push) {
      dispatch({ type: R_GLOBAL.USER_CLICKED, value: to })
    }
    onClickInner && onClickInner(ev, to)
  }

  return { ...args, to, onClick }
}

// type can be 'div', 'disable', 'Link', 'tr', or 'span' as well
export function Link({ type = undefined, back = false, ...props }) {
  const history = useHistory()
  const [state, dispatch] = useContext(GlobalContext)

  const args = linkTo({ props, state, dispatch, history })
  let { onClick, to, ...rest } = args
  const onClickWrap = useCallback(
    (ev) => {
      onClick(ev)
      if (back) {
        history.goBack()
      } else {
        history.push(to)
      }
    },
    [history, to, back, onClick]
  )

  switch (type) {
    case 'disable':
      return <div {...rest} />
    case 'div':
      return <div {...rest} onClick={onClickWrap} />
    case 'span':
      return <span {...rest} onClick={onClickWrap} />
    case 'button':
      return <button {...rest} onClick={onClickWrap} />
    case 'tr':
      return <tr {...rest} onClick={onClickWrap} />
    default:
      return <InnerLink {...args} />
  }
}

export function NavLink(props) {
  const history = useHistory()
  const [state, dispatch] = useContext(GlobalContext)
  let {
    disable,
    icon,
    bareStyle,
    className = '',
    label,
    center = false,
    children,
    smHideLabel,
    ...rest
  } = props

  const args = linkTo({ props: rest, state, dispatch, history })

  let mylabel = label || children

  // hide it on small
  if (smHideLabel) {
    mylabel = <div className="dn db-ns">{mylabel}</div>
  }
  if (!bareStyle) {
    className = `navlink ${className}`
  }

  return (
    <InnerNavLink activeClassName="active" className={className} {...args}>
      <div className={`flex-items ${center ? 'justify-center' : ''} nowrap`}>
        {icon}
        {mylabel}
      </div>
    </InnerNavLink>
  )
}

// react-menu-list
export function MenuLink({ className = 'ph3 pv2 hover-hilite', ...other }) {
  return (
    <MenuItem className={className}>
      <NavLink {...other} />
    </MenuItem>
  )
}

export function ExtLink({
  to,
  children = undefined,
  className = '',
  label = ''
}) {
  if (!children) {
    try {
      const url = new URL(to)
      children = `${label ? label : ''}${url.hostname}`
    } catch (err) {
      console.log({ err, to })
    }
  }
  return (
    <a href={to} className={className} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  )
}

export default Link
