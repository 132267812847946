import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const INITIAL_PARAMS = new URLSearchParams(window.location.search)
export function useSearchParams() {
  const search = useHistory().location.search
  const [params, setParams] = useState({ search, obj: INITIAL_PARAMS })
  // console.log("USING!", {search, params})
  useEffect(() => {
    if (search !== params.search) {
      // console.log("REDO with", {search})
      setParams({ search, obj: new URLSearchParams(search) })
    }
  }, [search, setParams, params])
  return params.obj
}
