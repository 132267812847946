import React from 'react'
import { site } from '../config'
import Link from 'tools/Links'

function Footer(props) {
  const year = new Date().getFullYear()

  return (
    <div className="footer f2">
      <div className="w-100 mt3 pb3 pl3">
        <Link to="/p/copyright">
          {site.copyright} {year}
        </Link>
      </div>
    </div>
  )
}

export default Footer
