import React, { useContext, useState } from 'react'
import { NavLink, ExtLink } from 'tools/Links'
import GlobalContext from 'reducer/global'
import paths from 'constants/paths'

export function NavBar({ navState, state }) {
  const [open, setOpen] = navState
  const [{ user }] = useContext(GlobalContext)

  return (
    <div className="nav flex flex-column justify-between items-center h-100 pt3">
      <div className="bar bg-term-dark halo-inner">
        <div className="controls action" onClick={() => setOpen(!open)}>
          <div className="head flex-center ml-auto">navi</div>
          <div className="handle flex-center f2 pointer">
            {open ? <>&laquo;</> : <>navi &raquo;</>}
          </div>
        </div>
        {open ? (
          <div className="bg-term-dark-inner items">
            <NavLink
              to={paths.pageView + 'ENR/primer'}
              label="Primer"
              className="item mt1 ttu"
            />
            <NavLink
              to={paths.pageView + 'ENR/primer-modern'}
              label="Modern Galactic"
              className="item"
            />
            <PivotNav title="Gazeteer" pkey="gaz">
              <NavLink
                to={paths.pageView + 'ENR/gaz-map'}
                label="Galactic Map"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/gaz-power'}
                label="Nations"
                className="item"
              />
              <NavLink
                to={paths.tableView + 'ENR/Organizations'}
                label="Organizations"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/gaz-locales'}
                label="Locales"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/species'}
                label="Species"
                className="item"
              />
              <NavLink
                to={paths.tableView + 'ENR/NPCs'}
                label="Notable NPCs"
                className="item"
              />
            </PivotNav>
            <PivotNav title="Game Rules" pkey="rules" start={false}>
              <NavLink
                to={paths.pageView + 'ENR/Rules-01'}
                label="Introduction"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/Rules-02'}
                label="Characters"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/Rules-02'}
                label="Gameplay"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/Rules-02'}
                label="Equipment"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/Rules-02'}
                label="Vehicles"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/Rules-02'}
                label="Metaverse"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/Rules-02'}
                label="Foes"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/Rules-02'}
                label="Game Mastery"
                className="item"
              />
              <NavLink
                to={paths.pageView + 'ENR/Rules-02'}
                label="Meta Rules"
                className="item"
              />
            </PivotNav>
            <PivotNav
              title="Community"
              bottom={true}
              pkey="community"
              start={false}
            >
              <div className="item">
                <ExtLink to="https://discord.gg/6JFtQ4h">Discord</ExtLink>
              </div>
              {user.isAuthed ? (
                <>
                  <NavLink
                    to={paths.pageView + 'ENR/rogues-gallery'}
                    label="Rogue's Gallery"
                    className="item"
                  />
                  <NavLink
                    to={paths.pageView + 'ENR/characters'}
                    label="Characters"
                    className="item"
                  />
                  <NavLink
                    to={paths.pageView + 'ENR/scenarios'}
                    label="Scenarios"
                    className="item"
                  />
                  <NavLink
                    to={paths.pageView + 'ENR/stories'}
                    label="Stories &amp; Art"
                    className="item"
                  />
                </>
              ) : (
                <>
                  <NavLink
                    to="/signon"
                    label="Rogue's Gallery"
                    className="item"
                  />
                  <NavLink to="/signon" label="Characters" className="item" />
                  <NavLink to="/signon" label="Scenarios" className="item" />
                  <NavLink
                    to="/signon"
                    label="Stories &amp; Art"
                    className="item"
                  />
                </>
              )}
            </PivotNav>
            <div className="item">
              <ExtLink to="https://revenant.studio">Revenant Studio</ExtLink>
            </div>
          </div>
        ) : (
          <div className="closed-bar"></div>
        )}
      </div>
    </div>
  )
}

// <NavLink
//   to={paths.pageView + 'forums'}
//   label="Forums"
//   className="item"
// />
// ideally this state is remembered between sessions
function PivotNav({ title, children, pkey: key, bottom = false, start = true }) {
  const [pivot, setPivot] = useState(start)
  // const [
  //   {
  //     prefs: { nav }
  //   },
  //   dispatch
  // ] = useContext(GlobalContext)
  // const closed = !nav[key]

  return (
    <>
      <div className="item pointer" onClick={() => setPivot(!pivot)}>
        <div>
          <i className={`action fa fa-${pivot ? 'minus' : 'plus'} mr1 f1`} />
          {title}
        </div>
      </div>
      {pivot && (
        <>
          <div className="ml3">{children}</div>
          {bottom ? (
            <div
              style={{ margin: '0.5rem', borderBottom: 'solid 0.5px #ffffff55' }}
            />
          ) : null}
        </>
      )}
    </>
  )
}

export default NavBar
