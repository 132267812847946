import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import NavBar from './NavBar'
import Opine from './Opine'
import Sidebar from './Sidebar'
import GlobalContext from 'reducer/global'
import Notify from 'tools/Notify'

import { site } from '../config'

function Root({ children }) {
  const [state] = useContext(GlobalContext)
  const [navState, setNavState] = useState(window.innerWidth > 700)
  const [opineState, setOpineState] = useState(true)
  const [sidebarState, setSidebarState] = useState(true)
  const {
    page: { title }
  } = state

  useEffect(() => {
    if (title) {
      document.title = title + ' - ' + site.name
    }
  }, [title])

  return (
    <>
      <div className="content">
        <div className={`main ${navState ? '' : 'closed'}`}>
          <div className="home">
            <div className="icon">
              <Link to="/">
                <img
                  alt="logo"
                  src={site.logo}
                  className={navState ? 'closed' : ''}
                />
              </Link>
            </div>
          </div>
          <div className="header flex-center">
            <div className="title bg-term-dark halo-inner">
              <div className="controls intitle flex justify-center">
                <div className="head flex-center ml-auto">topic</div>
              </div>
              <div className="bg-term-dark-inner content">
                {title ? title : 'In the twilight of time...'}
              </div>
            </div>
            <div className="bar1"></div>
          </div>
          <NavBar navState={[navState, setNavState]} state={state} />
          <div className="body">
            {children}
            <Opine viewState={[opineState, setOpineState]} state={state} />
            <Sidebar viewState={[sidebarState, setSidebarState]} state={state} />
          </div>
        </div>
      </div>
      <Footer />
      <Notify />
    </>
  )
}

export default Root
