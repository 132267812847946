import React from 'react'

function Opine({ viewState: [opineState, setOpineState], state: { page } }) {
  if (!page.flavor) return null

  return (
    <div className={`opine bg-term-dark relative ${opineState ? '' : 'closed'}`}>
      <div
        className="controls inopine flex-center pointer action"
        onClick={() => setOpineState(!opineState)}
      >
        <div className="head flex-center ml-auto">opine</div>
        <div className="handle flex-center f2">
          <span className="quo">{opineState ? <>&laquo;</> : <>&raquo;</>}</span>
        </div>
      </div>
      <div className="background">
        <div className="dialog halo-inner">
          <div className="scroller opine-height">
            {page.flavor.map((row, x) => (
              <div className="mb2" key={x}>
                &gt;&gt;&gt;[{row.who}] {row.line}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Opine
