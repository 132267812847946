import apollo from 'utils/apollo'
import {
  authDropStates,
  authRequest,
  authXinitialState,
  AUTHX_ACTIONS,
  AUTHX_STATUS
} from 'utils/authx'
import { normalizeUser, normalizeUserParam, defaultUser } from 'utils/user'
import { CHG } from 'tools/Input'
import { cookies, COOKIE_NAME } from './state'

export const R_GLOBAL = {
  APOLLO_RESET: 1001,
  USER_CLICKED: 2001,
  USER_SET: 4001,
  USER_MERGE: 4002,
  TAGS_SET: 5001,
  PAGE_SET: 6001,
  PAGE_TITLE: 6003,
  PREFS_SET: 7001,
  PREFS_READY: 7002,
  PREFS_SHOW_COLS: 7004,
  PREFS_FILTER_COLS: 7005,
  NAV_ACTIVITY: 9000,
  SET_SAVING: 10000,
  MERGE_EDITABLE: 20000,
  MERGE: 30000
}

////////////////////////////////////////////////////////////////////////////////
function signOut(state, authx) {
  authDropStates()
  state.apollo.cache.reset()
  const revised = {
    ...state,
    authx,
    history: [window.location.pathname],
    user: defaultUser(),
    apollo: apollo(() => {}),
    apolloInit: false
  }
  authRequest('signout', {})
  return revised
}

////////////////////////////////////////////////////////////////////////////////
export function reducer(state, { type, ...action }) {
  switch (type) {
    case R_GLOBAL.SET_SAVING: {
      const { uuid, status } = action.value
      const savingKeys = { ...state.savingKeys }
      if (status === CHG.OK || status === CHG.SAVED) {
        delete savingKeys[uuid]
      } else {
        savingKeys[uuid] = status
      }
      const saving = Object.keys(savingKeys).length > 0
      return { ...state, saving, savingKeys }
    }

    case R_GLOBAL.APOLLO_RESET:
      return { ...state, apolloInit: true, apollo: apollo(action.dispatch) }

    // <Link /> history tracking
    case R_GLOBAL.USER_CLICKED:
      if (action.value === state.history[state.history.length - 1]) {
        return state
      }

      return {
        ...state,
        history: state.history.concat(action.value)
      }

    case R_GLOBAL.USER_SET:
      return { ...state, user: normalizeUser(action.value, true) }

    case R_GLOBAL.USER_MERGE:
      return { ...state, user: { ...state.user, ...action.value } }

    case R_GLOBAL.TAGS_SET:
      return {
        ...state,
        user: {
          ...state.user,
          profile: action.value,
          ...normalizeUserParam('profile', action.value)
        }
      }

    case R_GLOBAL.PREFS_SET: {
      const prefs = {
        ...state.prefs,
        [action.key]: { ...state.prefs[action.key], ...action.value }
      }
      // cookies.set(COOKIE_NAME, prefs, { path: '/' })
      return { ...state, prefs: { ...prefs, skip: true } }
    }

    case R_GLOBAL.PREFS_READY:
      return { ...state, prefs: { ...state.prefs, skip: false } }

    case R_GLOBAL.PAGE_SET: {
      // we want to always default some things to undefined, if they aren't
      // specified for a given page, so they doesn't carry from page to page
      const page = {
        ...state.page,
        flavor: undefined,
        sidebar: undefined,
        ...action.value
      }
      if (page.title || page.name) {
        document.title = `Codex${page.name ? ' - ' + page.name : ''}${
          page.title ? ' - ' + page.title : ''
        }`
      } else {
        document.title = 'Codex - Creative Tools'
      }
      return { ...state, page }
    }

    case R_GLOBAL.PAGE_TITLE: {
      // variant from PAGE_SET, also sets id as title
      const title = action.value
      const page = {
        ...state.page,
        flavor: undefined,
        sidebar: undefined,
        title,
        id: title
      }
      return { ...state, page }
    }

    // authx
    case AUTHX_ACTIONS.SIGNING_IN:
      return {
        ...state,
        authx: {
          ...authXinitialState(),
          targetAuthed: true,
          isAuthN: false,
          handshaking: true,
          redirect: false,
          error: undefined
        }
      }

    case AUTHX_ACTIONS.SIGN_OUT:
      if (state.authx.isAuthN) {
        return signOut(state, { ...authXinitialState() })
      }
      return state

    case AUTHX_ACTIONS.ERROR_CLEAR:
      return {
        ...state,
        authx: {
          ...state.authx,
          redirect: false,
          error: undefined
        }
      }

    case AUTHX_ACTIONS.ERROR_REDIRECT:
      return {
        ...state,
        authx: {
          ...state.authx,
          handshaking: false,
          isAuthN: false,
          refresh: false,
          error: action.value,
          redirect: true
        }
      }

    case AUTHX_ACTIONS.ERROR:
      return {
        ...state,
        authx: {
          ...state.authx,
          handshaking: false,
          isAuthN: false,
          refresh: false,
          redirect: false,
          error: action.value
        }
      }

    case AUTHX_ACTIONS.SIGNED_IN:
      return {
        ...state,
        authx: {
          ...state.authx,
          status: AUTHX_STATUS.IDENTIFIED,
          refresh: false,
          isAuthN: true,
          handshaking: false,
          redirect: false,
          error: undefined
        }
      }

    case AUTHX_ACTIONS.REFRESH_TOKEN:
      return {
        ...state,
        authx: {
          ...state.authx,
          refresh: action.value
        }
      }

    case AUTHX_ACTIONS.SIGNIN_TIMEOUT:
      if (state.authx.handshaking) {
        return {
          ...state,
          authx: {
            ...state.authx,
            error:
              'Timeout waiting for auth response, Sorry! (check privacy blockers)',
            handshaking: false
          }
        }
      }

      return state

    case R_GLOBAL.NAV_ACTIVITY: {
      let { menu, bar, value } = action
      if (menu) {
        menu = navActivity(state.nav.menu, menu, value)
        return { ...state, nav: { ...state.nav, menu } }
      }
      bar = navActivity(state.nav.bar, bar, value)
      return { ...state, nav: { ...state.nav, bar } }
    }

    case R_GLOBAL.MERGE_EDITABLE:
      return { ...state, ...action }

    case R_GLOBAL.PREFS_SHOW_COLS: {
      const show = state.prefs.show || {}
      const { key, ...rest } = action
      show[key] = { ...rest }
      const prefs = { ...state.prefs, show: { ...show } }
      cookies.set(COOKIE_NAME, prefs, { path: '/' })
      return { ...state, prefs: { ...prefs } }
    }

    case R_GLOBAL.PREFS_FILTER_COLS: {
      let filters = state.prefs.filters || {}
      const { key, reset, merge, set } = action
      if (reset) {
        filters = {}
      } else if (merge) {
        filters[key] = { ...(filters[key] || {}), ...merge }
      } else if (set) {
        filters[key] = set
      }
      const prefs = { ...state.prefs, filters: { ...filters } }
      cookies.set(COOKIE_NAME, prefs, { path: '/' })
      return { ...state, prefs: { ...prefs } }
    }

    case R_GLOBAL.MERGE: {
      return { ...state, ...action.value }
    }

    default:
      throw new Error(`no such action.type: ${type}!`)
  }
}

function navActivity(list, key, value) {
  return list.map((item) => {
    if (item.label === key) {
      return { ...item, activity: value }
    }
    return item
  })
}
