import React, { lazy, Suspense, useEffect, useContext } from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import NotifyContext, { notify } from 'tools/Notify/resolver'
import IdentifiedUser from 'tools/IdentifiedUser'
import { LoadingOverlay } from 'tools/Loader'
import paths from 'constants/paths'
import GlobalContext, { R_GLOBAL } from 'reducer/global'
import { asyncRefreshToken, AUTHX_ACTIONS } from 'utils/authx'
import { READ_SELF } from 'components/User/graphql'
import { when } from 'utils/function'
import Signon from 'components/Signon'
// import config from 'constants/config'
import 'site/enr/style.scss'

// const Root = lazy(() => import('site/' + config.site + '/Root'))
const Home = lazy(() => import('site/enr/Home'))
// const Project = lazy(() => import('components/Project'))
// const Org = lazy(() => import('components/Org'))
const User = lazy(() => import('components/User'))
const PublicUser = lazy(() => import('components/User/Public'))
// const Apply = lazy(() => import('components/Apply'))
// const Studio = lazy(() => import('components/Studio'))
// const Feed = lazy(() => import('components/Feed'))
// const Learn = lazy(() => import('components/Learn'))
// const Meet = lazy(() => import('components/Meet'))
const ReturnCode = lazy(() => import('components/ReturnCode'))
const FormatTest = lazy(() => import('components/Admin/FormatTest'))
const ShowError = lazy(() => import('components/Admin/ShowError'))
const AdminSummary = lazy(() => import('components/Admin/Summary'))
const AdminUser = lazy(() => import('components/Admin/User'))
// const AdminJourney = lazy(() => import('components/Admin/Journey'))
// const Mentor = lazy(() => import('components/Admin/Mentor'))
const Doc = lazy(() => import('components/Doc'))
const PageView = lazy(() => import('components/Doc/Page/View'))
const PageEdit = lazy(() => import('components/Doc/Page/Edit'))
const TableView = lazy(() => import('components/Doc/Table/View'))
const TableEdit = lazy(() => import('components/Doc/Table/Edit'))
const Story = lazy(() => import('components/Story'))

const LOADING = <LoadingOverlay />

export function App({ Root }) {
  const [state, dispatch] = useContext(GlobalContext)
  const [, notifyDispatch] = useContext(NotifyContext)

  // initialize apollo
  useEffect(() => {
    if (!state.apolloInit) {
      dispatch({ type: R_GLOBAL.APOLLO_RESET, dispatch })
    }
  }, [state.apolloInit, dispatch])

  // Load user, refetch when we are able to do so (have a validation token)
  const { isAuthN, refresh } = state.authx
  useEffect(() => {
    if (refresh) {
      asyncRefreshToken(dispatch)
    }
  }, [refresh, dispatch])

  // Fetch profile after done signing in
  useEffect(() => {
    let isMounted = true
    if (isAuthN) {
      state.apollo
        .query({ query: READ_SELF, fetchPolicy: 'network-only' })
        .then(
          when(isMounted, ({ data: { self } }) => {
            dispatch({ type: R_GLOBAL.USER_SET, value: self })
          })
        )
        .catch((error) => {
          // do nothing
        })
    }
    return () => {
      isMounted = false
    }
  }, [isAuthN, state.apollo, dispatch])

  // convert authx errors to notify
  const authx_error = state.authx.error
  useEffect(() => {
    if (authx_error) {
      notify(notifyDispatch, { content: authx_error })
      dispatch({ type: AUTHX_ACTIONS.ERROR_CLEAR })
    }
  }, [authx_error, dispatch, notifyDispatch])

  return (
    <ApolloProvider client={state.apollo}>
      <Router>
        <Root>
          <Switch>
            <Route exact path="/">
              <Suspense fallback={LOADING}>
                <Home />
              </Suspense>
            </Route>
            <Route path={paths.userRoute}>
              <Suspense fallback={LOADING}>
                <User />
              </Suspense>
            </Route>
            <Route path={paths.userPublicRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <PublicUser />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.pageRoute}>
              <Suspense fallback={LOADING}>
                <Doc Child={PageView} />
              </Suspense>
            </Route>
            <Route path={paths.tableRoute}>
              <Suspense fallback={LOADING}>
                <Doc Child={TableView} />
              </Suspense>
            </Route>
            <Route path={paths.tableEditRoute}>
              <Suspense fallback={LOADING}>
                <Doc Child={TableEdit} />
              </Suspense>
            </Route>
            <Route path={paths.pageEditRoute}>
              <Suspense fallback={LOADING}>
                <Doc Child={PageEdit} />
              </Suspense>
            </Route>
            <Route path={paths.storyRoute}>
              <Suspense fallback={LOADING}>
                <Story />
              </Suspense>
            </Route>
            <Route path={paths.signon}>
              <Signon signout={false} />
            </Route>
            <Route path={paths.signup}>
              <Signon signout={false} />
            </Route>
            <Route path={paths.signout}>
              <Signon signout={true} />
            </Route>
            <Route path={paths.returnCodeRoute}>
              <Suspense fallback={LOADING}>
                <ReturnCode />
              </Suspense>
            </Route>
            <Route path={paths.admUserRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <AdminUser />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path={paths.admSummaryRoute}>
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <AdminSummary />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path="/format">
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <FormatTest />
                </Suspense>
              </IdentifiedUser>
            </Route>
            <Route path="/err">
              <IdentifiedUser>
                <Suspense fallback={LOADING}>
                  <ShowError />
                </Suspense>
              </IdentifiedUser>
            </Route>
          </Switch>
        </Root>
      </Router>
    </ApolloProvider>
  )
}

export default App
