import React from 'react'

function Sidebar({ viewState: [sidebarState, setSidebarState], state }) {
  const {
    page: { sidebar }
  } = state
  if (!sidebar) return null

  return (
    <div
      className={`sidebar ${sidebar.placement} bg-term-dark relative ${
        sidebarState ? '' : 'closed'
      }`}
    >
      <div
        className="controls insidebar flex-center pointer action"
        onClick={() => setSidebarState(!sidebarState)}
      >
        <div className="head flex-center ml-auto">info</div>
        <div className="handle flex-center f2">
          <span className="quo">
            {sidebarState ? <>&laquo;</> : <>&raquo;</>}
          </span>
        </div>
      </div>
      <div className="background">
        <div className="dialog halo-inner">
          <div className="scroller">{sidebar.content}</div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
