import axios from 'axios'

const SITES = {
  'codex.revenant.studio': 'codex',
  'localhost:3001': 'codex',
  'roguetrader.com': 'rt',
  'localhost:3002': 'rt',
  'wyrmstone.com': 'wst',
  'localhost:3003': 'wst',
  'enrequiem.com': 'enr',
  'localhost:3004': 'enr'
}

// TODO: update to allow more variants, such as off-host and on-host
const host = window.location.hostname
let port = window.location.port
let hostport = host
if (host === 'localhost') {
  hostport = host + ':' + port
}
let site = SITES[hostport]
let orig_port = ':' + port
let tls = true
if (process.env.NODE_ENV === 'development') {
  port = ':4000'
  tls = false
} else {
  if (
    (port === '80' && window.location.protocol === 'http') ||
    (port === '443' && window.location.protocol === 'https')
  ) {
    port = ''
  } else {
    port = ':' + port
  }
}

const config = {
  site,
  host,
  lane: 'p', // @LANE@
  build: '202106100759', // @BUILD@
  name: 'default',
  name_a: 'default',
  name_short: 'default',
  name_legal: 'default',
  nav_show: {
    icon: true,
    text: false
  },
  url: {
    http: `http${tls ? 's' : ''}://${host}${port}`,
    ws: `ws${tls ? 's' : ''}://${host}${port}/socket`,
    app: `http${tls ? 's' : ''}://${host}${port}`, // @APP@
    graphql: '/graphql',
    authapi: '/authx/v1/api/',
    pub: `https://i.revenant.studio/`
  },
  auth: {
    local: true,
    federated: ['google'],
    google: {
      appId:
        '370077008372-k4fvmcb357sdjjeu8q8hdnui00aphitq.apps.googleusercontent.com'
    }
  }
}

// TODO: move to global reducer
console.log(
  `getting http${tls ? 's' : ''}://${host}${orig_port}/codex/manifest.json`
)
axios
  .get(`http${tls ? 's' : ''}://${host}${orig_port}/codex/manifest.json`)
  .then(({ data }) => {
    config.name = data.name
    config.name_short = data.name_short
    config.name_an = data.name_an
    config.name_legal = data.name_legal
  })

export default config
