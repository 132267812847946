// while we could use interpolation for more editability, this is more efficient
export default {
  noId: '_',
  graphql: '/graphql',
  authapi: '/authx/v1/api/',
  forum: 'r',
  pageView: '/d/',
  pageRoute: '/d/:site?/:page?',
  pageEdit: '/adm/page/',
  pageEditRoute: '/adm/page/:site/:page',
  tableView: '/t/',
  tableRoute: '/t/:site?/:table?/:row?',
  tableEdit: '/adm/table/',
  tableEditRoute: '/adm/table/:site/:table',
  story: '/s/',
  storyRoute: '/s/:bookId?/:tabId?',
  // note: /ev is a direct route to the backend for email verification
  person: '/prefs',
  personPublic: '/u',
  userPublicRoute: '/u/:targetId?',
  userRoute: '/prefs/:targetId?/:tabId?/:code?',
  preferences: '/prefs/_/profile',
  unsubscribe: '/prefs/_/notify',
  password: '/prefs/_/pwreset',
  org: '/o',
  orgRoute: '/o/:orgId?/:tabId?/:folioId?/:projectId?',
  orgCreate: '/o/_/create',
  orgMine: '/o/_/mine',
  studio: '/x',
  studioRoute: '/x/:orgId?/:tabId?/:folioId?/:action?',
  apply: '/a',
  applyRoute: '/a/:folioId/:projectId?',
  project: '/p',
  projectRoute: '/p/:targetId?/:tabId?/:subId?/:subSubId?',
  projectCreate: '/p/_/create',
  projectMine: '/p/_/mine',
  feed: '/f',
  learn: '/l',
  learnCreate: '/l/_/edit',
  learnRoute: '/l/:targetId?/:action?',
  meet: '/m',
  meetRoute: '/m/:targetId?/:tabId?/:eventId?',
  meetCreate: '/m/_/create',
  meetMine: '/m/_/mine',
  meetAdmin: '/m/_/admin',
home: '/',
  div: '/v',
  divRoute: '/v/:tab?',
  signon: '/signon',
  signup: '/signup',
  signout: '/signout',
  returnCode: '/c',
  returnCodeRoute: '/c/:action?/:shortId?',
  admJourney: '/adm/journey',
  admJourneyRoute: '/adm/journey/:targetId?/:tabId?/:subId?',
  admFile: '/adm/file',
  admUser: '/adm/user',
  admUserRoute: '/adm/user',
  admMentor: '/adm/mentor',
  admMentorRoute: '/adm/mentor/:tabId?',
  admSummary: '/adm/summary',
  admSummaryRoute: '/adm/summary/:tabId?'
}
